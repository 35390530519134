import React, { useEffect, useState } from "react";
import { firebaseApp, db, storageRef } from "../firebase/firestore";
import { formatDate } from "../../utils/utils";
import placeholder128 from "../../../static/img/placeholder-128x128.png";
import { checkMailSub } from "../NewslletterSignUpForm";

export const AuthContext = React.createContext(0);

export const firebaseEmailSettings = {
  url: "https://occasio.online/settings",
};

export const storeRefUid = (inviteeUid) => {
  const { localStorage } = window;
  const refUid = localStorage.getItem("ref_uid");
  if (refUid) {
    const userRef = db.collection("users").doc(refUid);
    userRef.get().then((doc) => {
      if (doc.exists) {
        db.collection("ref").doc(inviteeUid).set({ inviter_uid: refUid });
      }
      localStorage.removeItem("ref_uid");
    });
  }
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(0);
  const [notVerifiedCount, setNotVerifiedUsers] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState(placeholder128);
  const [isAdmin, setIsAdmin] = useState(0);
  const [refUid, setRefUid] = useState("");
  const [firstName, setFirstName] = useState("");

  const refreshNotVerifiedUsersCount = (uid) => {
    getInvitedUsersCount(uid).then((u) =>
      setNotVerifiedUsers(u.notVerifiedCount),
    );
  };

  // reduce non verified counter without db write (to save on cloud usage)
  const reduceNotVerifiedUsersCount = () => {
    setNotVerifiedUsers(notVerifiedCount - 1);
  };

  const rereadRefUid = () => {
    setRefUid(window.localStorage.getItem("ref_uid"));
  };

  useEffect(() => {
    const handleAuthStateChange = (user) => {
      // create user document to store additional info. This covers both manually and website-created users
      if (user) {
        const isEmailAuth = user.providerData.reduce(
          (accumulator, currentValue) =>
            accumulator || currentValue.providerId === "password",
          false,
        );
        // non email auth are handled on sign up page
        const userRef = db.collection("users").doc(user.uid);
        if (isEmailAuth) {
          userRef.get().then((doc) => {
            if (!doc.exists) {
              userRef.set({});
              storeRefUid(user.uid);
              rereadRefUid();

              if (!user.emailVerified) {
                user.sendEmailVerification(firebaseEmailSettings);
              }
            }
            window.localStorage.removeItem("ref_uid");
          });
        }

        const userInfoRef = db.collection("usersInfo").doc(user.uid);

        userInfoRef.get().then((doc) => {
          // check if user is admin
          if (doc.exists) {
            setIsAdmin(!!doc.data().isAdmin);
          }

          // add signUpDate for all signup methods
          if (!doc.exists) {
            // TODO such db manipulations are better to be done on server side.
            userInfoRef.set({ signUpDate: user.metadata.creationTime });
          }
        });

        // TODO combine with the call in " if (isEmailAuth) {"
        userRef.get().then((doc) => {
          if (doc.exists) {
            setFirstName(doc.data().first_name);
          }
        });

        // non verified users count
        refreshNotVerifiedUsersCount(user.uid);

        // set avatar
        storageRef
          .child(`avatar/${user.uid}`)
          .getDownloadURL()
          .then((storeUrl) => {
            setAvatarUrl(storeUrl);
          })
          .catch(() => {
            // don't show error if avatar not found
          });

        // Check for mailchimp subscription
        checkMailSub();
      }

      setCurrentUser(user);
    };

    firebaseApp.auth().onAuthStateChanged(handleAuthStateChange);
    rereadRefUid();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        notVerifiedCount,
        reduceNotVerifiedUsersCount,
        avatarUrl,
        isAdmin,
        refUid,
        firstName,
        rereadRefUid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const getVerifierName = (uid) =>
  db
    .collection("verification")
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { changer_uid: verifierUid, status } = doc.data();
        if (verifierUid && status === "verified") {
          const verifierRef = db.collection("users").doc(verifierUid);
          return verifierRef.get().then((verDoc) => {
            if (verDoc.exists) {
              const data = verDoc.data();
              return data.first_name && data.surname
                ? {
                    id: verifierUid,
                    name: `${data.first_name} ${data.surname}`,
                  }
                : { id: verifierUid, name: "anonymous user" };
            }
            return { id: 0, name: "deleted user" };
          });
        }
      }
      return "";
    })
    .catch((e) => {
      alert(`Error getting verifier name. ${e}`);
      return "";
    });

const getVerifStatus = (uid) =>
  db
    .collection("verification")
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { status } = doc.data();
        if (status) {
          return status;
        }
      }
      return "";
    })
    .catch((e) => {
      alert(`Error getting verification status. ${e}`);
      return "";
    });

const getSignupDate = (uid) =>
  db
    .collection("usersInfo")
    .doc(uid)
    .get()
    .then((user) => {
      if (user.exists) {
        return new Date(user.data().signUpDate);
      }
      return "";
    })
    .catch(() => "");

export const getInvitedUsersCount = (uid) => {
  let allUsersIds = [];
  const verifOrRejectedUsersIds = [];
  return db
    .collection("ref")
    .where("inviter_uid", "==", uid)
    .get()
    .then((querySnapshot) => {
      const invUsersTotalPromise = [];
      querySnapshot.forEach((doc) => {
        invUsersTotalPromise.push(doc);
      });

      return Promise.all(invUsersTotalPromise);
    })
    .then((querySnapshot) => {
      allUsersIds = querySnapshot.map((u) => u.id);
      const verifiedAll = [];

      querySnapshot.forEach((doc) => {
        verifiedAll.push(db.collection("verification").doc(doc.id).get());
      });

      return Promise.all(verifiedAll);
    })
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.exists && doc.data().changer_uid) {
          verifOrRejectedUsersIds.push(doc.data().changer_uid);
        }
      });

      return {
        totalCount: allUsersIds.length,
        notVerifiedCount: allUsersIds.length - verifOrRejectedUsersIds.length,
        allUsersIds,
      };
    })
    .catch((e) => {
      alert(e);
      return {
        totalCount: 0,
        notVerifiedCount: 0,
        allUsersIds,
      };
    });
};

export const getInvitedUsers = async (uid) => {
  const usersIds = await getInvitedUsersCount(uid);
  const res = [];
  const usersTemp = [];
  usersIds.allUsersIds.forEach((uId) => {
    usersTemp.push(db.collection("users").doc(uId).get());
  });

  const usersData = await Promise.all(usersTemp);
  await Promise.all(
    usersData.map(async (u) => {
      if (u.exists) {
        const data = u.data();
        const name =
          data.first_name && data.surname
            ? `${data.first_name} ${data.surname}`
            : "anonymous user";
        const signUpDate = getSignupDate(u.id);
        const verifStatus = getVerifStatus(u.id);
        res.push({
          uid: u.id,
          name,
          signUpDate: formatDate(await signUpDate),
          signUpDateRaw: await signUpDate,
          isVerified: (await verifStatus) === "verified",
          isRejected: (await verifStatus) === "rejected",
        });
      }
    }),
  );
  // TODO add even deleted users
  return res.sort(
    (a, b) => b.signUpDateRaw.getTime() - a.signUpDateRaw.getTime(),
  );
};
