import firebase from "firebase/app/dist/index.cjs";
import "firebase/firestore/dist/index.cjs";
import "firebase/auth/dist/index.cjs";
import "firebase/storage/dist/index.cjs";
import "firebase/functions/dist/index.cjs";
import { navigate } from "gatsby";
import { storeRefUid } from "../auth/Auth";
import { isInviteOnlyMode } from "../../utils/utils";
// explicit import because webpack can't determine which version to get (browser or node) and throws "IDBIndex is not defined firebase"
// will be fixed with major version change https://github.com/firebase/firebase-js-sdk/issues/1797

const config = {
  apiKey: process.env.GATSBY_FIREBASE_KEY,
  authDomain: process.env.GATSBY_FIREBASE_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: String(process.env.GATSBY_FIREBASE_PROJECT_ID),
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(config);

const db = firebaseApp.firestore();
const storageRef = firebase.storage().ref();
const firebaseFunc = firebaseApp.functions();

const getCurrentUser = () => {
  firebaseApp.auth().useDeviceLanguage();
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const recaptchaVerifier = recaptchaContainer =>
  new firebase.auth.RecaptchaVerifier(recaptchaContainer);

const phoneAuthCredential = (verificationId, code) =>
  firebase.auth.PhoneAuthProvider.credential(verificationId, code);

const emailAuthCredential = (email, password) =>
  firebase.auth.EmailAuthProvider.credential(email, password);

const gglAuthProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  // this won't give access to the user phone, unless it's specified in https://aboutme.google.com/
  // recovery phone cannot be received
  // details https://issuetracker.google.com/issues/78151501
  // provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");

  return provider;
};

const fbAuthProvider = new firebase.auth.FacebookAuthProvider();

const gglSignup = () => {
  const button = document.getElementById("ggl-signup");
  button.classList.add("is-loading");
  firebaseApp
    .auth()
    .signInWithPopup(gglAuthProvider())
    .then(async result => {
      const refUid = localStorage.getItem("ref_uid");
      if (
        isInviteOnlyMode() &&
        result.additionalUserInfo.isNewUser &&
        !refUid
      ) {
        firebaseFunc.httpsCallable("deleteMe")();
        firebaseApp.auth().signOut();
        alert(
          "You need a referral code to register on OCCASIO. Please check Get invited page.",
        );
        navigate("/get-invited");
        return;
      }

      if (result.additionalUserInfo.isNewUser) {
        const { user } = result;
        const { profile } = result.additionalUserInfo;

        const userRef = db.collection("users").doc(user.uid);
        await userRef.set({
          first_name: profile.given_name,
          surname: profile.family_name,
        });

        storeRefUid(user.uid);

        if (profile.picture) {
          const avatarRef = storageRef.child(`avatar/${user.uid}`);
          const blob = await fetch(profile.picture).then(r => r.blob());
          await avatarRef.put(blob);
        }
      }

      window.localStorage.removeItem("ref_uid");

      navigate("/settings");
    })
    .catch(error => {
      if (error.code === "auth/web-storage-unsupported") {
        alert("Please enable 3rd party cookies to sign up with Google.");
      } else {
        alert(error.message);
      }
    })
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const fbSignup = () => {
  const button = document.getElementById("fb-signup");
  button.classList.add("is-loading");
  firebaseApp
    .auth()
    .signInWithPopup(fbAuthProvider)
    .then(async result => {
      const refUid = localStorage.getItem("ref_uid");
      if (
        isInviteOnlyMode() &&
        result.additionalUserInfo.isNewUser &&
        !refUid
      ) {
        firebaseFunc.httpsCallable("deleteMe")();
        firebaseApp.auth().signOut();
        alert(
          "You need a referral code to register on OCCASIO. Please check Get invited page.",
        );
        navigate("/get-invited");
        return;
      }

      if (result.additionalUserInfo.isNewUser) {
        const { user } = result;
        const { profile } = result.additionalUserInfo;

        const userRef = db.collection("users").doc(user.uid);
        await userRef.set({
          first_name: profile.first_name,
          surname: profile.last_name,
        });

        storeRefUid(user.uid);

        const avatarRef = storageRef.child(`avatar/${user.uid}`);
        const blob = await fetch(profile.picture.data.url).then(r => r.blob());
        await avatarRef.put(blob);
      }

      window.localStorage.removeItem("ref_uid");

      navigate("/settings");
    })
    .catch(error => {
      if (error.code === "auth/web-storage-unsupported") {
        alert("Please enable 3rd party cookies to sign up with Google.");
      } else {
        alert(error.message);
      }
    })
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

export {
  firebaseApp,
  firebaseFunc,
  db,
  storageRef,
  getCurrentUser,
  recaptchaVerifier,
  phoneAuthCredential,
  emailAuthCredential,
  gglAuthProvider as googleAuthProvider,
  gglSignup,
  fbSignup,
};
