// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-donations-index-js": () => import("./../src/pages/donations/index.js" /* webpackChunkName: "component---src-pages-donations-index-js" */),
  "component---src-pages-flutterwave-index-js": () => import("./../src/pages/flutterwave/index.js" /* webpackChunkName: "component---src-pages-flutterwave-index-js" */),
  "component---src-pages-gdpr-index-js": () => import("./../src/pages/gdpr/index.js" /* webpackChunkName: "component---src-pages-gdpr-index-js" */),
  "component---src-pages-get-invited-index-js": () => import("./../src/pages/get-invited/index.js" /* webpackChunkName: "component---src-pages-get-invited-index-js" */),
  "component---src-pages-hall-of-fame-index-js": () => import("./../src/pages/hall-of-fame/index.js" /* webpackChunkName: "component---src-pages-hall-of-fame-index-js" */),
  "component---src-pages-login-index-js": () => import("./../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-private-page-index-js": () => import("./../src/pages/private-page/index.js" /* webpackChunkName: "component---src-pages-private-page-index-js" */),
  "component---src-pages-ref-index-js": () => import("./../src/pages/ref/index.js" /* webpackChunkName: "component---src-pages-ref-index-js" */),
  "component---src-pages-ref-users-index-js": () => import("./../src/pages/ref-users/index.js" /* webpackChunkName: "component---src-pages-ref-users-index-js" */),
  "component---src-pages-register-index-js": () => import("./../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-settings-index-js": () => import("./../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-userlist-index-js": () => import("./../src/pages/userlist/index.js" /* webpackChunkName: "component---src-pages-userlist-index-js" */),
  "component---src-pages-version-index-js": () => import("./../src/pages/version/index.js" /* webpackChunkName: "component---src-pages-version-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-donate-page-js": () => import("./../src/templates/donate-page.js" /* webpackChunkName: "component---src-templates-donate-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-successful-donation-page-js": () => import("./../src/templates/successful-donation-page.js" /* webpackChunkName: "component---src-templates-successful-donation-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-text-banner-page-js": () => import("./../src/templates/text-banner-page.js" /* webpackChunkName: "component---src-templates-text-banner-page-js" */),
  "component---src-templates-text-page-js": () => import("./../src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-timeline-page-js": () => import("./../src/templates/timeline-page.js" /* webpackChunkName: "component---src-templates-timeline-page-js" */),
  "component---src-templates-under-construction-js": () => import("./../src/templates/under-construction.js" /* webpackChunkName: "component---src-templates-under-construction-js" */)
}

