import React, { useEffect, useRef } from "react";
import { getNames, registerLocale } from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import dayjs from "dayjs";
import { withPrefix } from "gatsby";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import LANGUAGES_LIST from "./langList";

const getCountriesList = () => {
  registerLocale(en);
  return Object.values(getNames("en"))
    .sort((a, b) => a.localeCompare(b))
    .map((e) => ({ value: e, label: e }));
};

const getLanguagesList = () => {
  return Object.values(LANGUAGES_LIST)
    .map((lang) => lang.name)
    .sort((a, b) => a.localeCompare(b))
    .map((e) => ({ value: e, label: e }));
  // may not be the fastest way to transform array
};

const getAccountTypeList = () => {
  return [
    "Student",
    "School",
    "College",
    "University",
    "Intern",
    "Employee",
    "Freelancer",
    "Employer",
    "Agency",
    "Training",
    "Investor",
    "Entrepreneur",
    "Consultancy",
    "Sponsor",
    "Government Agency",
    "NGO",
    "Charity / Foundation",
    "Press",
    "Other",
  ].map((e) => ({ value: e, label: e }));
  // may not be the fastest way to transform array
};

const getSectorList = () => {
  return [
    "Energy Equipment & Services",
    "Oil, Gas & Consumable Fuels",
    "Chemicals",
    "Construction Materials",
    "Containers & Packaging",
    "Metals & Mining",
    "Paper & Forest Products",
    "Aerospace & Defense",
    "Building Products",
    "Construction & Engineering",
    "Electrical Equipment",
    "Industrial Conglomerates",
    "Machinery",
    "Trading Companies & Distributors",
    "Commercial Services & Supplies",
    "Professional Services",
    "Air Freight & Logistics",
    "Airlines",
    "Marine",
    "Road & Rail",
    "Transportation Infrastructure",
    "Auto Components",
    "Automobiles",
    "Household Durables",
    "Leisure Products",
    "Textiles, Apparel & Luxury Goods",
    "Hotels, Restaurants & Leisure",
    "Diversified Consumer Services",
    "Distributors",
    "Internet & Direct Marketing Retail",
    "Multiline Retail",
    "Specialty Retail",
    "Food & Staples Retailing",
    "Beverages",
    "Food Products",
    "Tobacco",
    "Household Products",
    "Personal Products",
    "Health Care Equipment & Supplies",
    "Health Care Providers & Services",
    "Health Care Technology",
    "Biotechnology",
    "Pharmaceuticals",
    "Life Sciences Tools & Services",
    "Banks",
    "Thrifts & Mortgage Finance",
    "Diversified Financial Services",
    "Consumer Finance",
    "Capital Markets",
    "Mortgage Real Estate Investment Trusts (REITs)",
    "Insurance",
    "IT Services",
    "Software",
    "Communications Equipment",
    "Technology Hardware, Storage & Peripherals",
    "Electronic Equipment, Instruments & Components",
    "Semiconductors & Semiconductor Equipment",
    "Diversified Telecommunication Services",
    "Wireless Telecommunication Services",
    "Media",
    "Entertainment",
    "Interactive Media & Services",
    "Electric Utilities",
    "Gas Utilities",
    "Multi-Utilities",
    "Water Utilities",
    "Independent Power and Renewable Electricity Producers",
    "Equity Real Estate Investment Trusts (REITs)",
    "Real Estate Management & Development",
  ]
    .sort()
    .map((e) => ({ value: e, label: e }));
  // may not be the fastest way to transform array
};

const getSkillsList = () => {
  return [
    "Web Development",
    "App Development",
    "Mobile Development",
    "Blockchain",
    "Events Management",
    "Sales",
    "Marketing",
    "Digital Marketing",
    "Social Media",
    "PR & Promotion",
    "Finance",
    "HR",
    "Business Development",
    "Other",
  ].map((e) => ({ value: e, label: e }));
};

// Makes a required field for components that don't support "required" property
const MakeRequired = ({ required }) => (
  <input
    tabIndex={-1}
    autoComplete="off"
    style={{
      display: "block",
      opacity: 0,
      height: 0,
      borderTopWidth: "0px",
      borderBottomWidth: "1px",
      padding: 0,
    }}
    required={required}
  />
);

const isURLValid = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
};

// date validation. format: 31-12-2019
const isValidDate = (dateString) => {
  const split = dateString.split("-");
  const day = split[0];
  if (day === undefined) {
    return false;
  }
  const presumedDate = new Date(split[2], split[1] - 1, split[0]);

  return presumedDate.getDate() === Number(day);
};

// dateString must be in format 31-12-1900
const getAge = (dateString) => {
  if (dateString === undefined || dateString === "") {
    return "";
  }

  const today = new Date();
  const split = dateString.split("-");
  const birthDate = new Date(split[2], split[1] - 1, split[0]);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

// full age validation
const isFullAge = (date) => {
  return getAge(date) < 18;
};

const formatDate = (date) => {
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [day, month, year].join("-");
};

const getRandomArrayElem = (arr) => {
  return arr[(Math.random() * arr.length) | 0];
};

// for comparing round dates, drop hours and munites
const roundDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

const cloneDate = (date) => {
  return new Date(date.getTime());
};

const copyToClipboard = (text) => {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

// React hooks have old value in setInterval, this allows to use timer and get fresh hooks value
// from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

// add js to show/hide dropdown by click
// use with caution, because stopPropagation() prevents other onClick event to fire
const useDropdown = () => {
  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }

  // Dropdowns for Bulma
  const $dropdowns = getAll(".dropdown:not(.is-hoverable)");

  if ($dropdowns.length > 0) {
    $dropdowns.forEach(function ($el) {
      $el.addEventListener("click", function (event) {
        event.stopPropagation();
        $el.classList.toggle("is-active");
      });
    });

    document.addEventListener("click", function (event) {
      closeDropdowns();
    });
  }

  function closeDropdowns() {
    $dropdowns.forEach(function ($el) {
      $el.classList.remove("is-active");
    });
  }
};

function isNumeric(n) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// react way of loading external scripts
const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

const convertHashtagsToURLFormat = (hashtags, addHashtag = false) =>
  hashtags.map((hashtag) => `${addHashtag ? "%23" : ""}${hashtag}`).join(",");

// check if currently on a specific page
const isPageURL = (pageUrl) =>
  window.location.pathname.replace(/\/+$/, "") ===
  `${withPrefix("/")}${pageUrl}`;

// time in minutes since date in a specific localStorage var
const timeSinceEvent = (varName) => {
  const val = window.localStorage.getItem(varName);
  if (val) {
    const valDate = dayjs(val);
    return dayjs().diff(valDate, "minute");
  }
  return 99999;
};

const isInviteOnlyMode = () =>
  process.env.DISABLE_REGISTRATION_WITHOUT_INVITE !== undefined &&
  process.env.DISABLE_REGISTRATION_WITHOUT_INVITE === "true";

const isUnderConstructionMode = () =>
  process.env.SHOW_UNDER_CONSTRUCTION_PAGE !== undefined &&
  process.env.SHOW_UNDER_CONSTRUCTION_PAGE === "true";

const getRandomElem = (arr) => arr[Math.floor(Math.random() * arr.length)];

const HtmlString2Component = ({ str }) => {
  const html = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(str)
    .toString();
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export {
  getCountriesList,
  getLanguagesList,
  getAccountTypeList,
  getSectorList,
  MakeRequired,
  isURLValid,
  isValidDate,
  isFullAge,
  formatDate,
  getRandomArrayElem,
  roundDate,
  cloneDate,
  copyToClipboard,
  useInterval,
  useDropdown,
  getAge,
  getSkillsList,
  isNumeric,
  useScript,
  convertHashtagsToURLFormat,
  isPageURL,
  timeSinceEvent,
  isInviteOnlyMode,
  isUnderConstructionMode,
  getRandomElem,
  HtmlString2Component,
};
